import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import './App.css';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import Authorization from './components/Authorization';
import Container from './components/wizard/container';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" Component={HomePage}/>
          <Route path="/signin" Component={Authorization}/>
          <Route path="/dashboard" Component={Dashboard}/>
          <Route path="requestFeedback" Component={Container}/>
        </Routes>
    </Router>
  );
}

export default App;

