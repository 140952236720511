// CognitoConfig.js
export const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
};
export const poolRegion = process.env.REACT_APP_REGION;

export const oauth = {
    domain: process.env.REACT_APP_DOMAIN,
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
    responseType: 'code', // Authorization code grant
    clientId: process.env.REACT_APP_CLIENT_ID
};